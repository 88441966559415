.btn {
  padding: 1.5rem 1.8rem;
  transition: 0.2s;
  border-radius: 2.5rem;
  font-family: $font-nunito-sans;
  font-weight: $font-semibold;
  font-size: 1.6rem;
  height: 50px;
  text-align: center;
  min-width: 14rem;
  outline: none;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  &.btn-primary {
    color: $color-white;
    background-color: $color-red-1;

    &:hover {
      background-color: $color-red-2;
    }
  }

  &.btn-secondary {
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-grey-6;

    &:hover {
      background-color: $color-grey-3;
    }
  }

  &.btn-outlined {
    font-size: 15px!important;
    color: $color-black;
    background-color: $color-white;
    border: solid 1px #c0bfd1;
  }

  &.btn-primary-black {
    font-size: 15px!important;
    color: $color-white;
    background-color: $color-black;
    border: solid 1px #c0bfd1;
  }
}
