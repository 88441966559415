.page-contact {
  .hero-image-wrapper {
    padding: 100px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60rem;

    @media (max-width: 599px) {
      padding-bottom: 0;
    }

    .content-wrapper {
      color: $color-white;
      max-width: 54.5rem;

      .heading {
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0;
        color: white;

        @media (min-width: $lg) {
          font-size: 5rem;
        }
      }
    }
  }


  .main-banner {
    .icon-wrapper {
      min-width: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a,
    p {
      font-family: $font-nunito-sans;
      font-weight: $font-semibold;
      font-size: 1.6rem;
      color: $color-black!important;
    }

    @media (min-width: $md) {
      .contact-info {
        height: 52px;
        display: flex;
        align-items: center;
      }
    }
  }

  .form-wrapper {
    padding: 5rem 0 10rem;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(234,234,234,1) 35%);

    .section-heading {
      font-size: 1.8rem;
      line-height: 3rem;
      margin-bottom: 5rem;
    }

    .tw-container {
      max-width: 960px !important;
    }

    .v-input {
      font-family: $font-nunito-sans;
    }

    textarea {
      margin-top: 20px !important;
    }
  }

  iframe {
    width: 100%;
    height: 58rem;
  }
}

@media (min-width: 1024px) {
  .page-contact {
    .form-wrapper {
      padding: 0 0 10rem;
    }
  }
}
