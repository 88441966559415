.page-legal-notice {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;

  .heading-3 {
    font-size: 3rem;
    line-height: 4.2rem;
    margin-bottom: 4rem;
  }

  .parragraph-heading,
  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }

  p {
    font-family: $font-nunito-sans;
  }
}

@media screen and (min-width: 1024px) {
  .page-legal-notice {
    padding-top: 14.5rem;
    padding-bottom: 15rem;

    .heading-3 {
      font-size: 4rem;
    }
  }
}
