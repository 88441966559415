.menu {
  z-index: 2;

  @media (min-width: 1280px) {
    display: none;
  }

  .menu-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: $color-white;
    will-change: transform;
  }

  .menu-items {
    width: 100%;
    position: fixed;
    top: 8rem;
    left: 0;
    list-style: none;
    color: $color-black;
    z-index: 3;
    min-height: calc(100vh - 8rem);

    .menu-item {
      font-family: $font-nunito-sans;
      font-size: 2rem;
      position: relative;
      margin-bottom: 2.5rem;
      left: 100%;
      will-change: transform;
      color: $color-black;
      padding: 0 4rem;
      display: flex;
      cursor: pointer;

      &.collapsible-wrapper {
        .collapsible {
          span:first-child {
            font-family: "Nunito Sans", sans-serif;
            font-size: 2rem;
          }
        }

        .content {
          &.active {
            margin-top: 2.2rem;
            padding-left: 1rem;
          }

          a {
            font-family: $font-nunito-sans;
            font-size: 1.6rem;
            line-height: 2.8rem;
            display: flex;
            margin-bottom: 1.6rem;
            color: $color-black;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.subnav-content {
  position: absolute;
  max-width: 700px;
  width: 100%;
  background-color: #555;
  left: 0;
  z-index: 1;
  visibility:hidden;
}

@media (min-width: 640px) {
  .menu {
    .menu-items {
      .menu-item {
        margin-bottom: 4.5rem;
      }
    }
  }
}

@media (min-width: 1024px) {
  .menu {
    .menu-items {
      .menu-item {
        padding: 0 35%;
      }
    }
  }
}
