.page-error {
  color: $color-black;

  h1 {
    font-weight: $font-bold;
    font-size: 15rem;
  }

  p {
    font-size: 3rem;
  }
}
