.brands {
  padding-top: 5rem;
  padding-bottom: 5.5rem;

  .section-heading {
    margin-bottom: 4.5rem;
  }

  img {
    &.tu-mejor-prestamo-logo {
      width: 18.7rem;
      height: 7rem;
    }

    &.thirsa-logo {
      width: 11.4rem;
      height: 7rem;
    }

    &.krata-logo {
      width: 12rem;
      height: 4.7rem;
    }

    &.aecosan-logo {
      width: 13rem;
      height: 5.2rem;
    }

    &.api-logo {
      // width: 14rem;
      height: 4.5rem;
    }
  }
 
  @media screen and (max-width: $sm) {
    .logos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        margin: 3rem 0;
      }
    }
  }
  
}

@media screen and (min-width: 1024px) {
  .brands {
    padding-top: 9rem;
    padding-bottom: 2rem;

    .section-heading {
      margin-bottom: 4rem;
    }

    img {
      &.tu-mejor-prestamo-logo {
        width: 20.7rem;
        height: 7.8rem;
      }

      &.thirsa-logo {
        width: 12.6rem;
        height: 7.8rem;
      }

      &.krata-logo {
        width: 13.4rem;
        height: 5.2rem;
      }

      &.aecosan-logo {
        width: 16.2rem;
        height: 6.5rem;
      }

      &.api-logo {
        // width: 16.2rem;
        height: 5rem;
      }
    }
  }
}
