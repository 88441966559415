.banner {
  padding-top: 1.5rem;
  // padding-bottom: 1.5rem;

  .hero-image {
    min-height: 61.5rem!important;

    .heading {
      font-size: 2.5rem;
      line-height: 3.5rem;
      color: $color-black;
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner {
    padding-top: 8.3rem;
    // padding-bottom: 8.6rem;

    .hero-image {
      .heading {
        font-size: 4rem;
        line-height: 5.2rem;
      }
    }
  }
}
