@font-face {
  font-family: 'NimbusSanExt';
  src: url("/fonts/NimbusSanExt/NimbusSanExt-Reg.woff2") format("woff2"),
    url("/fonts/NimbusSanExt/NimbusSanExt-Reg.woff") format("woff"),
    url("/fonts/NimbusSanExt/NimbusSanExt-Reg.ttf") format("ttf");
  font-display: auto;
  font-style: normal;
  font-weight: $font-regular;
}

// h1 {
//   font-family: $font-nimbus;
//   font-size: 5rem!important;
//   font-weight: normal!important;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.2;
//   letter-spacing: normal;

//   @media (max-width: 1023px) {
//     font-size: 4rem;
//     line-height: 1.25;
//   }
// }

// h2 {
//   font-family: $font-nimbus;
//   font-size: 4rem!important;
//   font-weight: normal!important;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.25;
//   letter-spacing: normal;

//   @media (max-width: 1023px) {
//     font-size: 3.5rem;
//     line-height: 1.29;
//   }
// }

// h3 {
//   font-family: $font-nimbus;
//   font-size: 3rem!important;
//   font-weight: normal!important;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.4;
//   letter-spacing: normal;
//   color: $color-white;

//   @media (max-width: 1023px) {
//     font-size: 2.5rem;
//     line-height: 1.4;
//   }
// }

// h4 {
//   font-family: $font-nimbus;
//   font-size: 2.2rem!important;
//   font-weight: $font-bold!important;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.27;
//   letter-spacing: normal;
// }

// h5 {
//   font-family: $font-nunito-sans;
//   font-size: 2rem!important;
//   font-weight: $font-bold!important;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
// }

// h6 {
//   font-family: $font-nimbus;
//   font-size: 1.8rem;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.67;
//   letter-spacing: normal;
// }

.captionblack {
  font-family: $font-nunito-sans;
  font-size: 1.6rem;
  font-weight: $font-semibold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-black;
}
