.card-info {
  .icon {
    margin-bottom: 2rem;
  }

  .heading {
    font-size: 4rem;
    line-height: 5.2rem;
    color: $color-black;
  }

  .copy {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-top: .7rem;
  }
}

@media screen and (min-width: 1024px) {
  .card-info {
    .icon {
      margin-bottom: 4rem;
    }

    .heading {
      font-size: 6rem;
    }

    .copy {
      margin-top: 1.5rem;
    }
  }
}
