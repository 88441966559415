.news {
  padding-top: 160px;
  @media (max-width: $md) {
    padding-top: 80px;
  }
  .news-container{
    max-width: 600px !important;
    padding: 1rem;
    @media (min-width: $lg) {
      max-width: 936px !important;
    }
  }
  &--title{
    font-family: $font-nunito-sans;
    font-size: 4rem !important;
    font-weight: bold;
  }

  &--subtitle{
    font-size: 1.8rem !important;
    font-family: $font-nunito-sans;
  }

  .margin-titles {
    margin-left: 40px;
    @media (max-width: $sm) {
      margin-left: 0;
    }
  }

  .news-card {
    max-width: 360px;
    width: 100%;
    height: 440px;
    margin: 20px 40px;

    @media (max-width: $lg - 1) {
      height: auto;
      margin: 30px 40px;
      &:last-child {
        padding-bottom: 40px;
      }
    }
    @media (max-width: $sm) {
      margin: 30px 0;
    }

    .image {
      .news-image {
        width: 100%;
        height: 189px;
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title, .short-description, .news-link {
        font-family: $font-nunito-sans;
      }
      a {
        margin-top: 30px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .short-description {
        margin-top: 14px;
        font-size: 16px;
      }
      .news-link {
        display: inline-block;
        margin-top: 20px;
        position: relative;
        color: black;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          bottom: -5px;
          left: 0;
          background-color: black;
          height: 2px;
        }

        &:hover {
          color: $primary;
          &:after {
            background-color: $primary;
          }
        }
      }
    }

    a {
      color: black;
    }

    &.hovered {
      .title, .news-link {
        color: $color-red-1;
      }
      .news-link:after {
        background-color: $color-red-1;
      }
    }
  }
  .news-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 1024px) {
      margin-top: 70px;
    }
  }

  .title-mobile-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media (min-width: $md + 1) {
      display: none;
    }
    h2 {
      font-size: 35px !important;
    }
  }

  .title-bg-image {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    max-height: 500px;
    z-index: 0;

    &:before{
        content: "";
        display: block;
        padding-top: 62.5%;
    }

    .opacity-black {
      background-color: rgba($color: #000000, $alpha: 0.4);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
    }

    .main_image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .title-container {
      z-index: 20;
      color: white;
      width: 70%;
      padding: 35px;
      @media (max-width: $md) {
        display: none;
      }

      .title {
        width: 80%;
        margin-bottom: 25px;
      }
    }
  }

  .content {
    .date-container {
      margin-top: 24px;
      margin-bottom: 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: $md) {
        flex-direction: column-reverse;
      }

      .at-share-btn-elements {
        display: flex;
        justify-content: center;
      }
      .date {
        display: flex;
        align-items: center;
        @media (max-width: $md) {
          margin-top: 50px;
        }
      }
    }

    .rich-text {
      font-family: $font-nunito-sans!important;
      padding-bottom: 50px;
      border-bottom: 1px solid $border-line-blog;
      p{
        font-size:16px;
        margin-bottom: 1rem;
      }
      ul, ol{
        padding-left: 1.7rem;
        margin-top: 2rem;
        li{
          font-family: $font-nunito-sans!important;
          font-size:16px;
          margin-bottom: 1rem;
        }
      }

      h2{
        font-size: 22px;
        margin-bottom: 2rem;
        font-weight: 900;
        margin-top: 3rem;
        @media (max-width: $md) {
          margin-top: 18px;
        }
      }
    }
  }

  .related {
    margin-top: 89px;

    &-container {
      max-width: 600px !important;
      padding: 0 1rem;
      @media (min-width: $lg) {
        max-width: 1100px !important;
      }
    }

    h1{
      font-size: 30px!important;
      font-weight: 600;
    }
    &-news {
      &:focus {
        outline: none;
      }
      .flickity-viewport {
        margin: 0 80px;
      }
    }
  }

  &.news-index {
    padding-bottom: 50px;

    @media (min-width: 1024px) {
      padding-bottom: 80px;
    }

    .news-card {
      width: 100%;
      height: auto;

      @media (min-width: 768px) {
        max-width: none;
        width: 50%;
        margin: 40px 0;
        padding-left: 40px;
        padding-right: 40px;
      }
      @media (min-width: 1024px) {
        width: calc(100% / 3);
      }
    }
  }

  &.news-show {
    .title-mobile-container {
      h1 {
        font-size: 35px;
        @media (min-width: 1024px) {
          font-size: 40px;
        }
      }
    }

    .related-news {
      .news-card {
        .short-description {
          height: 88px;
        }
      }
    }
  }

  nav[role=navigation] {
    @media (min-width: $sm + 1) {
      padding: 0 40px;
    }
  }
}
.bg-grey {
  background-color: #f9f9f9;
}
