.navbar{
  // overflow: hidden;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: transparent;
  .container{
    padding: 0!important;
  }
  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      padding: 1.5rem 1.5rem 1.5rem 0;

      img {
        max-width: 15rem;
      }
    }

    .with-hover {
      padding: 1rem;
      border-radius: .8rem;
      transition: .3s;
    }

    a,
    button span {
      color: $color-white;
      outline: none;

      &:not(.icon) {
        font-family: $font-nunito-sans;
        font-weight: $font-regular;
        font-size: 1.6rem;
      }
    }

    .btn {
      font-size: 1.6rem;
      margin-left: 2rem;
      color: $color-white!important;
    }
  }

  .dropdown {
    &:hover {
      button {
        .icon {
          transform: rotate(-180deg);
        }
      }

      .dropdown-menu {
        visibility: visible;
        opacity: 1;
      }
    }

    .dropdown-menu {
      bottom: -9.85rem;
      left: 0;
      visibility: hidden;

      &:hover {
        display: flex;
        visibility: visible;
      }

      .dropdown-menu-content {
        flex-direction: column;
        width: 24rem;
        padding: 1.8rem 2rem;
        border-bottom-left-radius: .8rem;
        border-bottom-right-radius: .8rem;
        margin-top: 2rem;
        background-color: rgba(255, 255, 255, 0.17);

        a:hover {
          text-decoration: underline !important;

        }
      }
    }
  }

  &:not(.active) {
    .navbar-content {
      .logo {
        background-color: $color-white;
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
      }

      .with-hover {
        &:hover, &.active {
          background-color: rgba(255, 255, 255, 0.17);

        }
      }

      .dropdown {
        .dropdown-menu {
          transition: .3s;

          .dropdown-menu-content {
            border-top-left-radius: .8rem;
            border-top-right-radius: .8rem;
          }
        }
      }
    }
  }

  &.active {
    background-color: $color-white;

    &:not(.no-shadow) {
      box-shadow: 0 5px 11px -3px rgba(0, 0, 0, .15);
    }

    .navbar-content {
      a,
      button span {
        color: $color-black;

        &.btn {
          color: $color-white;
        }
      }
    }

    .dropdown {
      .dropdown-menu {
        .dropdown-menu-content {
          box-shadow: 0 5px 11px -3px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .c-hamburger-button {
      .hamburger-button__line {
        background-color: $color-black;
      }
    }
  }

  &.theme-white {
    background: white;

    .navbar-content {
      a,
      button span {
        color: $color-black;
      }

      .with-hover {
        &:hover, &.active {
          background-color: #F0F0F0;
          box-shadow: none;
        }
      }

      .dropdown-menu-content {
        background-color: #F0F0F0;
      }

      .c-hamburger-button {
        .hamburger-button__line {
          background-color: $color-black !important;
        }
      }
    }
  }

  &.dropdown-no-radius {
    &:not(.active) {
      .navbar-content {
        .dropdown {
          .dropdown-menu {
            .dropdown-menu-content {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .navbar{
    .navbar-content {
      .logo {
        img {
          max-width: 19.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .navbar{
    .navbar-content {
      .logo {
        padding: 2rem 2rem 2rem 0;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .navbar {
    .navbar-content {
      .with-hover {
        padding: 1.2rem 1.8rem;
        margin-left: 3px;
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .navbar{
    .container {
      max-width: 1440px;
    }
  }
}

.header-wizard{
  .logo{
    background-color: transparent!important;
  }
}
