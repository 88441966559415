.page-home {
  .hero-image-wrapper {
    padding: 100px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60rem;

    @media (max-width:599px) {
      padding: 100px 0 0 0;

    }
    @media (max-width:1023px) {
      flex-direction: column;
      .simulator-wrapper{
        width: 100%;
      }
      .content-wrapper{
        padding: 5rem 1rem;
      }
    }

    @media (max-width: 599px) {
      padding-bottom: 0;
    }
    
    .content-wrapper {
      color: $color-white;
      max-width: 54.5rem;

      .heading {
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0;
      }

      .text-content {
        font-size: 1.8rem;
        line-height: 3rem;
        letter-spacing: 0;
      }

      .logo-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 auto;

        &.efe-emprende {
          max-width: 15rem;
        }

        &.el-mundo {
          max-width: 13rem;
        }

        &.el-mundo-financiero {
          max-width: 17rem;
        }

        &.ei-estrategias {
          max-width: 13rem;
        }
      }
    }
  }
  .stadistics-container{
   text-align: center;
  }

  .info-cards {
    padding-top: 5rem;
    padding-bottom: 5rem;
    .section-heading {
      font-size: 2.5rem;
      line-height: 3.5rem;
      letter-spacing: 0;
      margin-bottom: 3rem;
    }

    .card-icon {
      .icon {
        height: 6rem;
      }
    }
  }

  .products {
    background-color: $color-black;
    padding-top: 5.5rem;
    padding-bottom: 2rem;

    .section-heading,
    .section-copy {
      color: $color-white;
      letter-spacing: 0;
    }

    .section-heading {
      font-size: 4rem;
      line-height: 5.2rem;
      margin-bottom: 0;
    }

    .section-copy {
      font-size: 1.8rem;
      line-height: 3rem;
    }

    .card-image {
      min-height: 36rem;
    }
  }

  .video-banner {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .section-heading {
      text-align: left;
      margin-bottom: 3rem;
    }

    .card-video {
      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        margin-top: 3rem;
      }
    }
  }

  .why-us {
    background-color: $color-grey-4;
    padding-top: 5rem;
    padding-bottom: 5.2rem;

    .section-heading {
      text-align: left;
      margin-bottom: 5rem;
    }

    .btn {
      margin-top: 5rem;
    }

    .card-icon {
      background: transparent;
      padding: 2rem 1rem 2rem 5px;

      .icon {
        height: 75px;
        width: auto;
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .page-home {
    .why-us {
      .card-icon {
        background: $color-white;
        padding: 4.5rem;

        &-container {
          max-width: 650px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .page-home {
    .hero-image-wrapper {
      padding: 135px 0 175px;
      min-height: 90rem;

      .content-wrapper {
        .heading {
          font-size: 5rem;
          line-height: 6rem;
          letter-spacing: 0;
        }

        .logo-container {
          margin: 0;

          &.efe-emprende {
            max-width: 17rem;
          }

          &.el-mundo {
            max-width: 15rem;
          }

          &.el-mundo-financiero {
            max-width: 19.5rem;
          }

          &.ei-estrategias {
            max-width: 16rem;
          }
        }
      }
    }

    .info-cards {
      padding-top: 14.5rem;
      padding-bottom: 15rem;

      .section-heading {
        font-size: 3rem;
        line-height: 4.2rem;
        margin-bottom: 10rem;
      }
    }

    .products {
      padding-top: 9.5rem;
      padding-bottom: 14.7rem;

      .card-image {
        min-height: 42rem;
      }
    }

    .video-banner {
      padding-top: 15rem;
      padding-bottom: 14.5rem;

      .section-heading {
        text-align: center;
        margin-bottom: 7.2rem;
      }

      .card-video {
        p {
          margin-top: 0rem;
        }

        .text-content {
          padding-left: 10rem;
        }
      }
    }

    .why-us {
      padding-top: 10rem;
      padding-bottom: 10rem;

      .section-heading {
        text-align: center;
        margin-bottom: 5rem;
      }

      .card-icon {
        padding: 3rem;
      }

      .btn {
        margin-top: 5rem;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .page-home {
    .products {
      .card-image {
        min-height: 60rem;
      }
    }

    .why-us {
      padding-top: 13rem;
      padding-bottom: 12rem;

      .section-heading {
        margin-bottom: 10rem;
      }

      .card-icon {
        padding: 4.5rem;
      }

      .btn {
        margin-top: 9rem;
      }
    }
  }
}
