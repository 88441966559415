.contact-us-component {
  height: 968px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('/img/background-contact-us-section.png');
  background-size: cover;
  @media (max-width: 1023px) {
    height: auto;
    background: transparent;
  }
  .image-background{
    position: absolute;
    width: 100%;
    @media (max-width:1023px) {
      position: relative;
      height: unset;
      display: none;
    }
    img{
      width: 100%;
      z-index: 0;
    }
  }
  .container-contact-us{
    @media (max-width:1023px) {
      width: 100%;
      // position: absolute;
      top: 0;
    }
    .title-contact-us{
      background-image: url('/img/background-contact-us-section.png');
      padding:5rem;
    }
  }
  h1 {
    font-size: 40px;
    font-weight: normal;
    font-family: $font-nimbus;
    color: white;
  }
  .wizard-container {
    z-index: 1;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    padding: 1.5rem 1.5rem 1.5rem 0;
    @media (max-width:1023px) {
      flex-direction: column;
      padding: 0;
      box-shadow: unset;
    }
    .w-element{
      padding: 5rem;
      @media (min-width:1024px) {
        min-width: 410px;
      }
      @media (max-width:1023px) {
        padding: 10%;
        width: 100%;
      }
      @media (max-width:767px) {
        padding: 4rem 1.5rem;
      }

      &.form{
        background-color: #ebebf0;
        max-width: 600px;
        @media (max-width:1023px) {
          max-width: unset;
        }
      }

    }
  }
}
