.account-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  $t: 1.5s;
  $d0: 942px;
  $d1: 2826px;

  svg {
    max-width: 25em;
    fill: none;
    stroke: #faf9fa;
    stroke-width: 3%;
  }

  g {
    transform: rotate(45deg);
    stroke: #51c041;
    // @include theme(stroke);
  }

  .mask use {
    stroke: #fff;
    stroke-linecap: round;
    stroke-dasharray: 2826px 942px;
    animation: r $t cubic-bezier(0.5, -0.43, 0.5, 1.43) infinite,
      s 0.5 * $t ease-in-out infinite alternate;
  }

  @keyframes r {
    to {
      transform: rotate(2turn);
    }
  }

  @keyframes s {
    0%,
    39% {
      stroke-dasharray: 942px 2826px;
    }
  }
}
