.card-blog {
  img {
    width: 100%;
    max-height: 18rem;
  }

  .heading, p, a {
    font-family: $font-nunito-sans;
  }

  .heading {
    font-size: 2rem;
    margin-top: 2.6rem;
    font-weight: bold;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-top: 1.4rem;
  }

  a {
    font-size: 1.6rem;
    line-height: 3.2rem;
    margin-top: 1.8rem;
    display: inline-flex;
    border-bottom: 2px solid currentColor;
    color: black;

    &:hover {
      color: $color-red-1;
    }
  }
}
