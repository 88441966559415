.page-mortgage-income {
  .hero-image {
    min-height: 67rem;
    .logos{
      display: flex;
      position: absolute;
      bottom: 40px;
      left: 15%;
      right: 15%;
      @media (max-width:$lg) {
        flex-direction: column;
        left: 10%;
        right: 10%;
      }

      .efe-emprende{
        width: 205px;
        margin: 0!important;
        padding: 1rem;
      }
      .el-mundo{
        width: 177px;
        margin: 0!important;
        padding: 1rem;

      }
      .el-mundo-financiero{
        width: 230px;
        margin: 0!important;
        padding: 1rem;

      }
      .ei-estrategias{
        width: 192px;
        margin: 0!important;
        padding: 1rem;

      }
    }
    .content-wrapper {
      color: $color-white;
      max-width: 54.5rem;

      .heading {
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0;
      }

      .text-content {
        font-size: 1.8rem;
        line-height: 3rem;
        letter-spacing: 0;
      }
    }
  }

  .about {
    padding-top: 2rem;
    padding-bottom: 5.5rem;

    .hero-wrapper {
      width: 100%;
      height: 34rem;

      .hero-image {
        min-height: unset;
      }
    }

    .text-wrapper {
      margin-top: 4rem;

      p {
        font-family: $font-nunito-sans;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
    }
  }

  .features {
    .section-heading {
      font-size: 2.5rem;
      line-height: 3.5rem;
      color: $color-black;
    }

    .secondary-copy {
      margin-top: 6rem;
      font-family: $font-nunito-sans;
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }

  .download {
    background-color: $color-red-1;
    margin-top: 5.5rem;
    padding: 7.5rem 0;

    p {
      font-size: 2rem;
      line-height: 3rem;
      color: $color-white;
    }
  }

  .news {
    padding-top: 5rem;
    padding-bottom: 0;

    .section-heading {
      margin-bottom: 3rem;
    }
  }

  .contact {
    background-color: $color-black;
    padding-top: 14.5rem;
    padding-bottom: 15rem;

    .section-heading {
      color: $color-white;
      margin-bottom: 5rem;
    }
  }

  &.page-product {
    .presence-media {
      padding-bottom: 7rem;

      .heading {
        margin-bottom: 3rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .page-mortgage-income {
    .hero-image {
      min-height: 75rem;

      .content-wrapper {
        .heading {
          font-size: 5rem;
          line-height: 6rem;
        }
      }
    }

    .about {
      padding-top: 15rem;
      padding-bottom: 15rem;

      .hero-wrapper {
        width: 56.6rem;
        height: 56.6rem;
      }

      .text-wrapper {
        max-width: 56.6rem;
        margin-top: 0rem;

        p {
          padding-left: 6rem;
        }
      }
    }

    .features {
      .section-heading {
        font-size: 3rem;
        line-height: 4.5rem;
      }

      .secondary-copy {
        margin-top: 6rem;
        font-family: $font-nunito-sans;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
    }

    .news {
      padding-top: 15rem;
      padding-bottom: 0;

      .section-heading {
        margin-bottom: 8rem;
      }
    }

    .download {
      margin-top: 15rem;
    }

    .brands {
      padding-bottom: 10rem;

      &.how-to-invest {
        padding-bottom: 1rem;
      }
    }

    &.page-product {
      .presence-media {
        padding-bottom: 15rem;
        .heading {
          padding-top: 14rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .page-mortgage-income {
    .about {
      .text-wrapper {
        p {
          padding-left: 0;
        }
      }
    }
  }
}
