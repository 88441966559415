.presence-media{

    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (min-width: 1024px) {
      padding-top: 0rem;
      padding-bottom: 10rem;
    }

    .heading {
      font-size: 2.5rem;
      line-height: 5.8rem;
      margin-bottom: 5.7rem;
      // font-weight: bold;
      @media (min-width:1024px) {
        padding-top: 10rem;
        font-size: 3rem;

      }
    }
    .img-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 100%;

      &.efe-emprende {
        width: 100%;
        width: 18.5rem;
      }

      &.el-mundo {
        width: 100%;
        max-width: 15.7rem;
      }

      &.el-mundo-financiero {
        width: 100%;
        max-width: 21rem;
      }

      &.ei-estrategias-de-inversion¡ {
        width: 100%;
        max-width: 16rem;
      }
    }

    .grid--media {
      grid-template-columns: repeat(2, minmax(100px, 210px));
      grid-gap: 20px;

      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 210px);
      }
    }
}
