.legal-component {
  padding: 10rem 0 4rem;

  @media (min-width: $lg) {
    padding: 10rem 0;
  }

  .select {
      border-radius: 6px;
      height: 41px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
      line-height: 39px;
      @media (max-width: $lg - 1) {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .material-icons {
              transition: transform .2s ease-in-out;
              &.open {
                  transform: rotate(180deg);
              }
          }
      }
      .options {
          top: 100%;
          width: 100%;
          box-shadow: inherit;
          font-size: 14px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          .option {
              font-family: $font-nunito-sans;
              background: white;
              &.selected {
                  background: $primary;
                  color: white;
              }
              &:last-child {
                  border-bottom-left-radius: 6px;
                  border-bottom-right-radius: 6px;
              }
          }
      }
      span {
        font-family: $font-nunito-sans;
      }
  }
  h3{
    font-family: 'Nunito Sans';
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 4rem;
    @media (min-width:1024px) {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 4rem;
    }
  }
  h4{
    margin-top: 3rem;
  }
  a {
      font-weight: 500;
      text-decoration: underline;
      color: $color-red-1;
      font-family: $font-nunito-sans;
  }
  p {
      margin-top: 1.5rem;
      font-family: $font-nunito-sans;
  }
  .menu-container{
      min-width: 210px;
      background-color: white;
      top: 75px;
  }
  .fixed-menu {
      background-color: white;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
      cursor: pointer;
      li {
          width: 210px;
          flex-grow: 1;
          font-weight: normal;
          font-size: 14px;
          padding: 10px 20px;
          &:first-child {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
          }
          &:last-child {
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
          }
          &.active {
              color: white;
              background: $primary;
              font-weight: 500;
          }
      }
  }
  table, th, td {
    border: 1px solid black;
    font-family: $font-nunito-sans;
  }
  table{
    margin: 2rem 0;
    font-size: 1.6rem;
    border-collapse: collapse;
    th{
      text-align: start;
    }
    th,td{
      padding: 1rem;
      @media (max-width:992px) {
        padding: 0.5rem;

      }
    }

  }
  // ul{
  //   font-size: 1.6rem;
  //   padding: 0 3rem;
  // }
}
