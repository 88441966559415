.page-services {
  .hero-image {
    min-height: 60rem;

    .content-wrapper {
      color: $color-white;
      max-width: 54.5rem;

      .heading {
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0;
      }

      .text-content {
        font-size: 1.8rem;
        line-height: 3rem;
        letter-spacing: 0;
      }
    }
  }

  .about {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $color-grey-5;

    @media (min-width: 1024px) {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }

    .text-content {
      text-align: center;
      max-width: 65rem;
      margin: 0 auto;
      margin-bottom: 5rem;

      @media (min-width: 1024px) {
        margin-bottom: 15rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 3rem;
      }
    }

    .card-icon {
      background: transparent;
      padding: 2rem 1rem 2rem 5px;

      .icon {
        height: 75px;
        width: auto;
      }

      .heading {
        font-size: 2.5rem;
        line-height: 3rem;
      }

      .copy {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
  }
}

@media (min-width: 640px) {
  .page-services {
    .about {
      .card-icon {
        background: $color-white;
        padding: 4.5rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .page-services {
    .hero-image {
      min-height: 75rem;

      .content-wrapper {
        .heading {
          font-size: 5rem;
          line-height: 6rem;
        }
      }
    }

    .about {
      padding-top: 10rem;
      padding-bottom: 10rem;

      .text-content {
        margin-bottom: 8rem;
      }

      .card-icon {
        .heading {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .page-services {
    .about {
      padding-top: 15rem;
      padding-bottom: 15rem;

      .text-content {
        margin-bottom: 15rem;
      }

      .card-icon {
        .heading {
          font-size: 3rem;
          line-height: 3.7rem;
        }
      }
    }
  }
}
