.c-hamburger-button {
  --width: 25px;
  --height: 16px;
  --line-height: 2px;
  position: relative;
  width: var(--width);
  height: var(--height);

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 768px) {
    transform: scale(1.2);
    transform-origin: 100% 50%;
  }

  .hambuger-button__wrapper,
  .hamburger-button__close,
  .hamburger-button__open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hamburger-button__line {
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--line-height);
    background-color: $color-white;
    border-radius: 5px;
  }

  .hamburger-button__open {
    .hamburger-button__line {
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        margin-top: calc(var(--line-height) * -0.5);
      }

      &:nth-child(3) {
        top: auto;
        bottom: 0;
      }
    }
  }

  .hamburger-button__close {
    .hamburger-button__line {
      background-color: $color-black;
      opacity: 0;
    }
  }
}

.c-hamburger-button {
  .hamburger-button__close {
    .hamburger-button__line {
      bottom: 0;
      top: auto;
      background-color: $color-black;

      &:nth-child(1) {
        transform-origin: 0 0;
      }
      &:nth-child(2) {
        transform-origin: 100% 0;
      }
    }
  }
}
