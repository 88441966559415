.page-about {
  .hero-image {
    min-height: 75rem;

    .content-wrapper {
      color: $color-white;
      max-width: 54.5rem;

      .heading {
        font-size: 5rem;
        line-height: 6rem;
        letter-spacing: 0;
      }

      .text-content {
        font-size: 1.8rem;
        line-height: 3rem;
        letter-spacing: 0;
      }
    }
  }

  .about {
    padding-top: 5rem;
    // padding-bottom: 5rem;
    background-color: $color-grey-5;

    @media (min-width: 1024px) {
      padding-top: 11rem;
      // padding-bottom: 15rem;
    }

    .sub-heading {
      text-align: center;
      font-size: 3rem;
      line-height: 6rem;
      letter-spacing: 0;
      font-weight: bold;
    }

    .text-content {
      text-align: center;
      max-width: 65rem;
      margin: 0 auto;
      margin-bottom: 5rem;

      p {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 2rem;
      }

      p:last-of-type {
        margin-bottom: 2.5rem;
      }

      .legal-text {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .bg-card {
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 3rem 1rem;

      &.h-limit {
        height: 24.5rem;
      }

      .heading {
        font-size: 4rem;
        line-height: 4.6rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        margin-top: 3rem;
      }
    }

    .card-circle {
      background-color: $color-white;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      width: 14rem;
      height: 11rem;
      margin: 2rem 0;
      @media (min-width: 375px) {
        width: 15rem;
        height: 13rem;
      }

    }
    .download {
      background-color: $color-red-1;
      margin-top: 5.5rem;
      padding: 7.5rem 0;

      p {
        font-size: 2rem;
        line-height: 3rem;
        color: $color-white;
      }
    }
    .brands {
      padding-top: 5rem;
      padding-bottom: 0;

      @media (min-width: 1024px) {
        padding-top: 10rem;
      }

      .heading {
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin-bottom: 5.7rem;
      }

      img {
        max-width: 100%;

        &.efe-emprende {
          width: 100%;
          width: 18.5rem;
        }

        &.el-mundo {
          width: 100%;
          max-width: 15.7rem;
        }

        &.el-mundo-financiero {
          width: 100%;
          max-width: 21rem;
        }

        &.ei-estrategias-de-inversion¡ {
          width: 100%;
          max-width: 16rem;
        }
      }
    }

    .grid--values {
      grid-template-columns: repeat(2, 150px);
      grid-gap: 20px;

      @media (min-width: 640px) {
        grid-template-columns: repeat(3, 150px);
      }
      @media (min-width: 1280px) {
        grid-template-columns: repeat(6, 150px);
      }

      .card-circle {
        label {
          font-family: "Nunito Sans", sans-serif;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }

    .grid--media {
      grid-template-columns: repeat(2, minmax(100px, 210px));
      grid-gap: 20px;

      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 210px);
      }
    }
  }
}

.padding-bottom-banner{
  padding-bottom: 10rem;
}
@media screen and (min-width: 1024px) {
  .page-about {
    .about {
      padding-top: 11rem;
      // padding-bottom: 15rem;

      .bg-card {
        padding: 5rem;
      }

      .text-content {
        margin-bottom: 15rem;
      }
    }
  }
}
