.card-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .img-container {
    height: 84px;
    img {
      height: 100%;
    }
  }

  .copy-wrapper {
    margin-top: 2rem;
    min-height: 7.2rem;
    display: flex;
    align-items: flex-start;

    p {
      font-family: $font-nunito-sans;
      font-weight: $font-bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  &#hand-shake {
    .img-container {
      img {
        height: 70px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .card-feature {
    .copy-wrapper {
      align-items: center;
    }
  }
}
