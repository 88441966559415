.fixed-menu {
  border-radius: 1rem;
  border: 1px solid $color-grey-6;
  position: sticky;
  top: 15rem;
  overflow: hidden;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .18);

  .menu-item {
    font-family: $font-nunito-sans;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 1.3rem 1.8rem;
    background-color: $color-white;
    cursor: pointer;
    transition: 0.3s;
    // border-bottom: 1px solid $color-grey-6;

    &.active {
      background-color: $color-red-1;

      a {
        color: $color-white;
      }
    }

    &:hover {
      &:not(.active) {
        background-color: $color-grey-3;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    a {
      color: $color-black;
    }
  }
}
