.video-list {
  background-color: $color-black;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .section-heading {
    color: $color-white;
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .video-list {
    padding-top: 13rem;
    padding-bottom: 15rem;
  }
}
