.card-icon {
  background-color: $color-white;
  padding: 4.5rem;

  .icon {
    width: 6.5rem;
    margin-bottom: 2.8rem;
  }

  .heading,
  .copy {
    font-family: $font-nunito-sans;
    color: $color-black;
  }

  .heading {
    font-weight: $font-bold;
    font-size: 2rem;
  }

  .copy {
    font-weight: $font-regular;
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-top: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .card-icon {
    padding: 6.5rem 4.5rem;
  }
}
