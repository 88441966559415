.footer {
  background-color: $color-black;
  padding: 5rem 0 5rem;

  a,
  p {
    font-family: $font-nunito-sans;
    font-weight: $font-semibold;
    font-size: 1.8rem;
    line-height: 3rem;
    color: $color-white;
  }

  img {
    &.social-media{
      width: 2.5rem;
      height: 2.5rem;
      &[alt="Twitter"] {
        margin-top: 2px;
      }
    }

    &.confianza-en-linea {
      width: 8rem;
      height: 8rem;
    }

    &.confianza-online {
      width: 7.5rem;
      height: 7.5rem;
    }
  }

  .copywright {
    font-weight: $font-regular;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-top: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    padding: 9rem 0 6rem;

    .copywright {
      margin-top: 4rem;
    }
  }
}
