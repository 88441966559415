.card-video {
  &.theme-black {
    color: $color-white;
  }

  &.font-18 {
    p {
      font-size: 1.8rem;
    }
  }

  p {
    font-family: $font-nunito-sans;
    font-weight: $font-regular;
    margin-top: 3rem;
  }

  .btn {
    width: max-content;
  }

  iframe {
    width: 100%;
    height: 19rem;
  }
}

@media screen and (min-width: 768px) {
  .card-video {
    p {
      margin-top: 0;
    }

    iframe {
      width: 400px;
      height: 224px;
    }
  }
}

@media (min-width: 1280px) {
  .card-video {
    iframe {
      width: 570px;
      height: 320px;
    }
  }
}
