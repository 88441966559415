.collapsible-wrapper {
  flex-direction: column;
  outline: none;

  .collapsible {
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
      .icon {
        transform: rotate(-180deg);
      }
    }

    .icon {
      color: $color-grey-7;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    outline: none;
    transition: max-height 0.2s ease-out;
  }
}
