.page-faqs {
  .parragraph-heading {
    font-size: 1.8rem;
    line-height: 3rem;
  }

  ul {
    list-style: disc;
    padding-left: 3rem;

    li {
      font-size: 1.6rem;
      line-height: 2.8rem;
      font-family: "Nunito Sans", sans-serif;
    }
  }

  .collapsible-wrapper {
    .collapsible {
      span {
        font-size: 1.8rem;
        line-height: 3rem;
        text-align: left;

        &.icon {
          font-size: 2.5rem;
        }
      }
    }

    .content {
      p {
        font-family: $font-nunito-sans;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {

}
