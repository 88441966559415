@charset "UTF-8";
@import '~flickity/css/flickity.css';
@font-face {
  font-family: "NimbusSanExt";
  src: url("/fonts/NimbusSanExt/NimbusSanExt-Reg.woff2") format("woff2"), url("/fonts/NimbusSanExt/NimbusSanExt-Reg.woff") format("woff"), url("/fonts/NimbusSanExt/NimbusSanExt-Reg.ttf") format("ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
.captionblack {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-size: 62.5% !important;
}

.base {
  background-color: #FFFFFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input,
span,
a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

p {
  line-height: 3rem;
}

button,
a {
  line-height: 1.15;
}

a {
  text-decoration: none !important;
}

[v-cloak] {
  display: none;
}

.thank-you-container {
  height: 60vh;
}

.section-heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #000000;
  margin-bottom: 7.2rem;
}

@media screen and (min-width: 1024px) {
  .section-heading {
    font-size: 3rem;
    line-height: 5.8rem;
  }
}
.tw-container {
  max-width: 100% !important;
  padding: 0 16px !important;
}
@media (min-width: 600px) {
  .tw-container {
    width: 90%;
    max-width: 1280px !important;
  }
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;
  z-index: 1500;
}
@media (min-width: 993px) {
  .cookie-consent {
    height: 200px;
  }
}
@media (min-width: 1200px) {
  .cookie-consent {
    height: 160px;
  }
}
@media (max-width: 992px) {
  .cookie-consent {
    padding: 15px 30px;
    height: unset;
  }
}
@media (max-width: 767px) {
  .cookie-consent {
    flex-direction: column;
  }
}
.cookie-consent__container {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
}
.cookie-consent__container .cookie-consent__message {
  font-family: "Nunito Sans", sans-serif;
  font-size: 22px;
  font-weight: 900;
}
@media (max-width: 992px) {
  .cookie-consent__container .cookie-consent__message {
    font-size: 18px;
  }
}
.cookie-consent__container .cookie-consent__message-subtitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
}
@media (max-width: 992px) {
  .cookie-consent__container .cookie-consent__message-subtitle {
    font-size: 14px;
  }
}
.cookie-consent__container .cookie-consent__message-subtitle a {
  color: white;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 18px !important;
}
@media (max-width: 992px) {
  .cookie-consent__container .cookie-consent__message-subtitle a {
    font-size: 14px !important;
  }
}
.cookie-consent__buttons {
  display: flex;
  flex-direction: row-reverse;
}
@media (min-width: 768px) {
  .cookie-consent__buttons {
    flex-direction: row;
  }
}
.cookie-consent__buttons button, .cookie-consent__buttons a {
  margin: 10px 5px;
}
@media (min-width: 1200px) {
  .cookie-consent__buttons button, .cookie-consent__buttons a {
    margin: 10px 10px;
  }
}
.cookie-consent__buttons .btn-outlined:hover {
  background: #d9d9d9;
}

.btn {
  padding: 1.5rem 1.8rem;
  transition: 0.2s;
  border-radius: 2.5rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  height: 50px;
  text-align: center;
  min-width: 14rem;
  outline: none;
}
.btn:focus, .btn:hover, .btn:active {
  outline: none;
}
.btn.btn-primary {
  color: #FFFFFF;
  background-color: #b70219;
}
.btn.btn-primary:hover {
  background-color: #990215;
}
.btn.btn-secondary {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #c0bfd1;
}
.btn.btn-secondary:hover {
  background-color: #ebebf0;
}
.btn.btn-outlined {
  font-size: 15px !important;
  color: #000000;
  background-color: #FFFFFF;
  border: solid 1px #c0bfd1;
}
.btn.btn-primary-black {
  font-size: 15px !important;
  color: #FFFFFF;
  background-color: #000000;
  border: solid 1px #c0bfd1;
}

.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.card-info .icon {
  margin-bottom: 2rem;
}
.card-info .heading {
  font-size: 4rem;
  line-height: 5.2rem;
  color: #000000;
}
.card-info .copy {
  font-size: 1.8rem;
  line-height: 3rem;
  margin-top: 0.7rem;
}

@media screen and (min-width: 1024px) {
  .card-info .icon {
    margin-bottom: 4rem;
  }
  .card-info .heading {
    font-size: 6rem;
  }
  .card-info .copy {
    margin-top: 1.5rem;
  }
}
.card-icon {
  background-color: #FFFFFF;
  padding: 4.5rem;
}
.card-icon .icon {
  width: 6.5rem;
  margin-bottom: 2.8rem;
}
.card-icon .heading,
.card-icon .copy {
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
}
.card-icon .heading {
  font-weight: 700;
  font-size: 2rem;
}
.card-icon .copy {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .card-icon {
    padding: 6.5rem 4.5rem;
  }
}
.card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem 2.5rem;
  color: #FFFFFF;
}
.card-image .heading {
  font-size: 4rem;
  line-height: 4.6rem;
}
.card-image .copy {
  font-size: 1.8rem;
  line-height: 3rem;
  margin-top: 2.4rem;
}
.card-image .btn {
  margin-top: 2rem;
}

@media screen and (min-width: 1024px) {
  .card-image {
    padding: 6.5rem 4.6rem;
  }
}
.card-video.theme-black {
  color: #FFFFFF;
}
.card-video.font-18 p {
  font-size: 1.8rem;
}
.card-video p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  margin-top: 3rem;
}
.card-video .btn {
  width: max-content;
}
.card-video iframe {
  width: 100%;
  height: 19rem;
}

@media screen and (min-width: 768px) {
  .card-video p {
    margin-top: 0;
  }
  .card-video iframe {
    width: 400px;
    height: 224px;
  }
}
@media (min-width: 1280px) {
  .card-video iframe {
    width: 570px;
    height: 320px;
  }
}
.card-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-feature .img-container {
  height: 84px;
}
.card-feature .img-container img {
  height: 100%;
}
.card-feature .copy-wrapper {
  margin-top: 2rem;
  min-height: 7.2rem;
  display: flex;
  align-items: flex-start;
}
.card-feature .copy-wrapper p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.card-feature#hand-shake .img-container img {
  height: 70px;
}

@media screen and (min-width: 1024px) {
  .card-feature .copy-wrapper {
    align-items: center;
  }
}
.card-blog img {
  width: 100%;
  max-height: 18rem;
}
.card-blog .heading, .card-blog p, .card-blog a {
  font-family: "Nunito Sans", sans-serif;
}
.card-blog .heading {
  font-size: 2rem;
  margin-top: 2.6rem;
  font-weight: bold;
}
.card-blog p {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 1.4rem;
}
.card-blog a {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-top: 1.8rem;
  display: inline-flex;
  border-bottom: 2px solid currentColor;
  color: black;
}
.card-blog a:hover {
  color: #b70219;
}

.fixed-menu {
  border-radius: 1rem;
  border: 1px solid #c0bfd1;
  position: sticky;
  top: 15rem;
  overflow: hidden;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.18);
}
.fixed-menu .menu-item {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  padding: 1.3rem 1.8rem;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: 0.3s;
}
.fixed-menu .menu-item.active {
  background-color: #b70219;
}
.fixed-menu .menu-item.active a {
  color: #FFFFFF;
}
.fixed-menu .menu-item:hover:not(.active) {
  background-color: #ebebf0;
}
.fixed-menu .menu-item:last-child {
  border-bottom: none;
}
.fixed-menu .menu-item a {
  color: #000000;
}

.c-hamburger-button {
  --width: 25px;
  --height: 16px;
  --line-height: 2px;
  position: relative;
  width: var(--width);
  height: var(--height);
}
.c-hamburger-button:focus {
  outline: none;
}
@media screen and (min-width: 768px) {
  .c-hamburger-button {
    transform: scale(1.2);
    transform-origin: 100% 50%;
  }
}
.c-hamburger-button .hambuger-button__wrapper,
.c-hamburger-button .hamburger-button__close,
.c-hamburger-button .hamburger-button__open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.c-hamburger-button .hamburger-button__line {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--line-height);
  background-color: #FFFFFF;
  border-radius: 5px;
}
.c-hamburger-button .hamburger-button__open .hamburger-button__line:nth-child(1) {
  top: 0;
}
.c-hamburger-button .hamburger-button__open .hamburger-button__line:nth-child(2) {
  top: 50%;
  margin-top: calc(var(--line-height) * -0.5);
}
.c-hamburger-button .hamburger-button__open .hamburger-button__line:nth-child(3) {
  top: auto;
  bottom: 0;
}
.c-hamburger-button .hamburger-button__close .hamburger-button__line {
  background-color: #000000;
  opacity: 0;
}

.c-hamburger-button .hamburger-button__close .hamburger-button__line {
  bottom: 0;
  top: auto;
  background-color: #000000;
}
.c-hamburger-button .hamburger-button__close .hamburger-button__line:nth-child(1) {
  transform-origin: 0 0;
}
.c-hamburger-button .hamburger-button__close .hamburger-button__line:nth-child(2) {
  transform-origin: 100% 0;
}

.menu {
  z-index: 2;
}
@media (min-width: 1280px) {
  .menu {
    display: none;
  }
}
.menu .menu-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: #FFFFFF;
  will-change: transform;
}
.menu .menu-items {
  width: 100%;
  position: fixed;
  top: 8rem;
  left: 0;
  list-style: none;
  color: #000000;
  z-index: 3;
  min-height: calc(100vh - 8rem);
}
.menu .menu-items .menu-item {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2rem;
  position: relative;
  margin-bottom: 2.5rem;
  left: 100%;
  will-change: transform;
  color: #000000;
  padding: 0 4rem;
  display: flex;
  cursor: pointer;
}
.menu .menu-items .menu-item.collapsible-wrapper .collapsible span:first-child {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2rem;
}
.menu .menu-items .menu-item.collapsible-wrapper .content.active {
  margin-top: 2.2rem;
  padding-left: 1rem;
}
.menu .menu-items .menu-item.collapsible-wrapper .content a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  display: flex;
  margin-bottom: 1.6rem;
  color: #000000;
}
.menu .menu-items .menu-item.collapsible-wrapper .content a:last-child {
  margin-bottom: 0;
}

.subnav-content {
  position: absolute;
  max-width: 700px;
  width: 100%;
  background-color: #555;
  left: 0;
  z-index: 1;
  visibility: hidden;
}

@media (min-width: 640px) {
  .menu .menu-items .menu-item {
    margin-bottom: 4.5rem;
  }
}
@media (min-width: 1024px) {
  .menu .menu-items .menu-item {
    padding: 0 35%;
  }
}
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group label {
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: 5px;
}
.form-group .v-input__slot {
  border-radius: 10px !important;
  height: 50px;
  min-height: unset !important;
  margin-bottom: 0.2px !important;
}
.form-group .v-input__slot .v-text-field__details {
  margin-bottom: 0.2px !important;
}
.form-group .v-input__slot input {
  font-family: "Nunito Sans", sans-serif;
}
.form-group .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
.form-group .red {
  color: #b70219 !important;
}
.form-group .v-text-field__details {
  margin-top: 5px;
}

.v-messages__message {
  color: red;
}

.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  border: 1px solid black !important;
}

.collapsible-wrapper {
  flex-direction: column;
  outline: none;
}
.collapsible-wrapper .collapsible {
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapsible-wrapper .collapsible.active .icon {
  transform: rotate(-180deg);
}
.collapsible-wrapper .collapsible .icon {
  color: #535b6c;
}
.collapsible-wrapper .content {
  max-height: 0;
  overflow: hidden;
  outline: none;
  transition: max-height 0.2s ease-out;
}

.account-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-loader svg {
  max-width: 25em;
  fill: none;
  stroke: #faf9fa;
  stroke-width: 3%;
}
.account-loader g {
  transform: rotate(45deg);
  stroke: #51c041;
}
.account-loader .mask use {
  stroke: #fff;
  stroke-linecap: round;
  stroke-dasharray: 2826px 942px;
  animation: r 1.5s cubic-bezier(0.5, -0.43, 0.5, 1.43) infinite, s 0.75s ease-in-out infinite alternate;
}
@keyframes r {
  to {
    transform: rotate(2turn);
  }
}
@keyframes s {
  0%, 39% {
    stroke-dasharray: 942px 2826px;
  }
}

.presence-media {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media (min-width: 1024px) {
  .presence-media {
    padding-top: 0rem;
    padding-bottom: 10rem;
  }
}
.presence-media .heading {
  font-size: 2.5rem;
  line-height: 5.8rem;
  margin-bottom: 5.7rem;
}
@media (min-width: 1024px) {
  .presence-media .heading {
    padding-top: 10rem;
    font-size: 3rem;
  }
}
.presence-media .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.presence-media img {
  max-width: 100%;
}
.presence-media img.efe-emprende {
  width: 100%;
  width: 18.5rem;
}
.presence-media img.el-mundo {
  width: 100%;
  max-width: 15.7rem;
}
.presence-media img.el-mundo-financiero {
  width: 100%;
  max-width: 21rem;
}
.presence-media img.ei-estrategias-de-inversion¡ {
  width: 100%;
  max-width: 16rem;
}
.presence-media .grid--media {
  grid-template-columns: repeat(2, minmax(100px, 210px));
  grid-gap: 20px;
}
@media (min-width: 1024px) {
  .presence-media .grid--media {
    grid-template-columns: repeat(4, 210px);
  }
}

.navbar {
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: transparent;
}
.navbar .container {
  padding: 0 !important;
}
.navbar .navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .navbar-content .logo {
  padding: 1.5rem 1.5rem 1.5rem 0;
}
.navbar .navbar-content .logo img {
  max-width: 15rem;
}
.navbar .navbar-content .with-hover {
  padding: 1rem;
  border-radius: 0.8rem;
  transition: 0.3s;
}
.navbar .navbar-content a,
.navbar .navbar-content button span {
  color: #FFFFFF;
  outline: none;
}
.navbar .navbar-content a:not(.icon),
.navbar .navbar-content button span:not(.icon) {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}
.navbar .navbar-content .btn {
  font-size: 1.6rem;
  margin-left: 2rem;
  color: #FFFFFF !important;
}
.navbar .dropdown:hover button .icon {
  transform: rotate(-180deg);
}
.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
}
.navbar .dropdown .dropdown-menu {
  bottom: -9.85rem;
  left: 0;
  visibility: hidden;
}
.navbar .dropdown .dropdown-menu:hover {
  display: flex;
  visibility: visible;
}
.navbar .dropdown .dropdown-menu .dropdown-menu-content {
  flex-direction: column;
  width: 24rem;
  padding: 1.8rem 2rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.17);
}
.navbar .dropdown .dropdown-menu .dropdown-menu-content a:hover {
  text-decoration: underline !important;
}
.navbar:not(.active) .navbar-content .logo {
  background-color: #FFFFFF;
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}
.navbar:not(.active) .navbar-content .with-hover:hover, .navbar:not(.active) .navbar-content .with-hover.active {
  background-color: rgba(255, 255, 255, 0.17);
}
.navbar:not(.active) .navbar-content .dropdown .dropdown-menu {
  transition: 0.3s;
}
.navbar:not(.active) .navbar-content .dropdown .dropdown-menu .dropdown-menu-content {
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}
.navbar.active {
  background-color: #FFFFFF;
}
.navbar.active:not(.no-shadow) {
  box-shadow: 0 5px 11px -3px rgba(0, 0, 0, 0.15);
}
.navbar.active .navbar-content a,
.navbar.active .navbar-content button span {
  color: #000000;
}
.navbar.active .navbar-content a.btn,
.navbar.active .navbar-content button span.btn {
  color: #FFFFFF;
}
.navbar.active .dropdown .dropdown-menu .dropdown-menu-content {
  box-shadow: 0 5px 11px -3px rgba(0, 0, 0, 0.15);
}
.navbar.active .c-hamburger-button .hamburger-button__line {
  background-color: #000000;
}
.navbar.theme-white {
  background: white;
}
.navbar.theme-white .navbar-content a,
.navbar.theme-white .navbar-content button span {
  color: #000000;
}
.navbar.theme-white .navbar-content .with-hover:hover, .navbar.theme-white .navbar-content .with-hover.active {
  background-color: #F0F0F0;
  box-shadow: none;
}
.navbar.theme-white .navbar-content .dropdown-menu-content {
  background-color: #F0F0F0;
}
.navbar.theme-white .navbar-content .c-hamburger-button .hamburger-button__line {
  background-color: #000000 !important;
}
.navbar.dropdown-no-radius:not(.active) .navbar-content .dropdown .dropdown-menu .dropdown-menu-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media screen and (min-width: 1024px) {
  .navbar .navbar-content .logo img {
    max-width: 19.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .navbar .navbar-content .logo {
    padding: 2rem 2rem 2rem 0;
  }
}
@media screen and (min-width: 1400px) {
  .navbar .navbar-content .with-hover {
    padding: 1.2rem 1.8rem;
    margin-left: 3px;
  }
}
@media screen and (min-width: 1680px) {
  .navbar .container {
    max-width: 1440px;
  }
}
.header-wizard .logo {
  background-color: transparent !important;
}

.footer {
  background-color: #000000;
  padding: 5rem 0 5rem;
}
.footer a,
.footer p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #FFFFFF;
}
.footer img.social-media {
  width: 2.5rem;
  height: 2.5rem;
}
.footer img.social-media[alt=Twitter] {
  margin-top: 2px;
}
.footer img.confianza-en-linea {
  width: 8rem;
  height: 8rem;
}
.footer img.confianza-online {
  width: 7.5rem;
  height: 7.5rem;
}
.footer .copywright {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 5rem;
}

@media screen and (min-width: 1024px) {
  .footer {
    padding: 9rem 0 6rem;
  }
  .footer .copywright {
    margin-top: 4rem;
  }
}
.video-list {
  background-color: #000000;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.video-list .section-heading {
  color: #FFFFFF;
  margin-bottom: 5rem;
}

@media screen and (min-width: 1024px) {
  .video-list {
    padding-top: 13rem;
    padding-bottom: 15rem;
  }
}
.brands {
  padding-top: 5rem;
  padding-bottom: 5.5rem;
}
.brands .section-heading {
  margin-bottom: 4.5rem;
}
.brands img.tu-mejor-prestamo-logo {
  width: 18.7rem;
  height: 7rem;
}
.brands img.thirsa-logo {
  width: 11.4rem;
  height: 7rem;
}
.brands img.krata-logo {
  width: 12rem;
  height: 4.7rem;
}
.brands img.aecosan-logo {
  width: 13rem;
  height: 5.2rem;
}
.brands img.api-logo {
  height: 4.5rem;
}
@media screen and (max-width: 640px) {
  .brands .logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .brands .logos .logo {
    margin: 3rem 0;
  }
}

@media screen and (min-width: 1024px) {
  .brands {
    padding-top: 9rem;
    padding-bottom: 2rem;
  }
  .brands .section-heading {
    margin-bottom: 4rem;
  }
  .brands img.tu-mejor-prestamo-logo {
    width: 20.7rem;
    height: 7.8rem;
  }
  .brands img.thirsa-logo {
    width: 12.6rem;
    height: 7.8rem;
  }
  .brands img.krata-logo {
    width: 13.4rem;
    height: 5.2rem;
  }
  .brands img.aecosan-logo {
    width: 16.2rem;
    height: 6.5rem;
  }
  .brands img.api-logo {
    height: 5rem;
  }
}
.banner {
  padding-top: 1.5rem;
}
.banner .hero-image {
  min-height: 61.5rem !important;
}
.banner .hero-image .heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #000000;
}

@media screen and (min-width: 1024px) {
  .banner {
    padding-top: 8.3rem;
  }
  .banner .hero-image .heading {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}
.steps .wrapper {
  background: #f8f8fa;
}
.steps .custom-container {
  max-width: 600px;
  margin: auto;
}
.steps .custom-container .item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 20rem;
  flex-direction: column;
}
.steps .custom-container .item label {
  font-size: 2.5rem;
  line-height: 4.6rem;
}
.steps .custom-container .item img, .steps .custom-container .item label {
  margin-bottom: 2.5rem;
}
.steps .custom-container .item p {
  font-family: "Nunito Sans", sans-serif;
  line-height: 2.8rem;
}
.steps .custom-container .item + .item {
  margin-top: 5rem;
}
.steps .right {
  display: none;
}

@media (min-width: 1024px) {
  .steps .wrapper {
    background: linear-gradient(90deg, #f8f8fa 50%, #fff 50%);
  }
  .steps .custom-container {
    max-width: 1200px;
  }
  .steps .custom-container .item {
    flex-direction: row;
    align-items: center;
    padding: 5rem;
  }
  .steps .custom-container .item p {
    padding-left: 5rem;
  }
  .steps .custom-container .item label {
    margin-left: 4rem;
  }
  .steps .custom-container .item img, .steps .custom-container .item label {
    margin-bottom: 0;
  }
  .steps .right {
    display: block;
  }
  .steps .right .item p {
    font-family: "Nunito Sans", sans-serif;
    line-height: 2.8rem;
  }
}
.contact-us-component {
  height: 968px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/img/background-contact-us-section.png");
  background-size: cover;
}
@media (max-width: 1023px) {
  .contact-us-component {
    height: auto;
    background: transparent;
  }
}
.contact-us-component .image-background {
  position: absolute;
  width: 100%;
}
@media (max-width: 1023px) {
  .contact-us-component .image-background {
    position: relative;
    height: unset;
    display: none;
  }
}
.contact-us-component .image-background img {
  width: 100%;
  z-index: 0;
}
@media (max-width: 1023px) {
  .contact-us-component .container-contact-us {
    width: 100%;
    top: 0;
  }
}
.contact-us-component .container-contact-us .title-contact-us {
  background-image: url("/img/background-contact-us-section.png");
  padding: 5rem;
}
.contact-us-component h1 {
  font-size: 40px;
  font-weight: normal;
  font-family: "Nunito Sans", sans-serif;
  color: white;
}
.contact-us-component .wizard-container {
  z-index: 1;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  padding: 1.5rem 1.5rem 1.5rem 0;
}
@media (max-width: 1023px) {
  .contact-us-component .wizard-container {
    flex-direction: column;
    padding: 0;
    box-shadow: unset;
  }
}
.contact-us-component .wizard-container .w-element {
  padding: 5rem;
}
@media (min-width: 1024px) {
  .contact-us-component .wizard-container .w-element {
    min-width: 410px;
  }
}
@media (max-width: 1023px) {
  .contact-us-component .wizard-container .w-element {
    padding: 10%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .contact-us-component .wizard-container .w-element {
    padding: 4rem 1.5rem;
  }
}
.contact-us-component .wizard-container .w-element.form {
  background-color: #ebebf0;
  max-width: 600px;
}
@media (max-width: 1023px) {
  .contact-us-component .wizard-container .w-element.form {
    max-width: unset;
  }
}

.page-error {
  color: #000000;
}
.page-error h1 {
  font-weight: 700;
  font-size: 15rem;
}
.page-error p {
  font-size: 3rem;
}

.page-home .hero-image-wrapper {
  padding: 100px 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60rem;
}
@media (max-width: 599px) {
  .page-home .hero-image-wrapper {
    padding: 100px 0 0 0;
  }
}
@media (max-width: 1023px) {
  .page-home .hero-image-wrapper {
    flex-direction: column;
  }
  .page-home .hero-image-wrapper .simulator-wrapper {
    width: 100%;
  }
  .page-home .hero-image-wrapper .content-wrapper {
    padding: 5rem 1rem;
  }
}
@media (max-width: 599px) {
  .page-home .hero-image-wrapper {
    padding-bottom: 0;
  }
}
.page-home .hero-image-wrapper .content-wrapper {
  color: #FFFFFF;
  max-width: 54.5rem;
}
.page-home .hero-image-wrapper .content-wrapper .heading {
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: 0;
}
.page-home .hero-image-wrapper .content-wrapper .text-content {
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0;
}
.page-home .hero-image-wrapper .content-wrapper .logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}
.page-home .hero-image-wrapper .content-wrapper .logo-container.efe-emprende {
  max-width: 15rem;
}
.page-home .hero-image-wrapper .content-wrapper .logo-container.el-mundo {
  max-width: 13rem;
}
.page-home .hero-image-wrapper .content-wrapper .logo-container.el-mundo-financiero {
  max-width: 17rem;
}
.page-home .hero-image-wrapper .content-wrapper .logo-container.ei-estrategias {
  max-width: 13rem;
}
.page-home .stadistics-container {
  text-align: center;
}
.page-home .info-cards {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.page-home .info-cards .section-heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  letter-spacing: 0;
  margin-bottom: 3rem;
}
.page-home .info-cards .card-icon .icon {
  height: 6rem;
}
.page-home .products {
  background-color: #000000;
  padding-top: 5.5rem;
  padding-bottom: 2rem;
}
.page-home .products .section-heading,
.page-home .products .section-copy {
  color: #FFFFFF;
  letter-spacing: 0;
}
.page-home .products .section-heading {
  font-size: 4rem;
  line-height: 5.2rem;
  margin-bottom: 0;
}
.page-home .products .section-copy {
  font-size: 1.8rem;
  line-height: 3rem;
}
.page-home .products .card-image {
  min-height: 36rem;
}
.page-home .video-banner {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.page-home .video-banner .section-heading {
  text-align: left;
  margin-bottom: 3rem;
}
.page-home .video-banner .card-video p {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 3rem;
}
.page-home .why-us {
  background-color: #f5f5f5;
  padding-top: 5rem;
  padding-bottom: 5.2rem;
}
.page-home .why-us .section-heading {
  text-align: left;
  margin-bottom: 5rem;
}
.page-home .why-us .btn {
  margin-top: 5rem;
}
.page-home .why-us .card-icon {
  background: transparent;
  padding: 2rem 1rem 2rem 5px;
}
.page-home .why-us .card-icon .icon {
  height: 75px;
  width: auto;
}

@media screen and (min-width: 640px) {
  .page-home .why-us .card-icon {
    background: #FFFFFF;
    padding: 4.5rem;
  }
  .page-home .why-us .card-icon-container {
    max-width: 650px;
  }
}
@media screen and (min-width: 1024px) {
  .page-home .hero-image-wrapper {
    padding: 135px 0 175px;
    min-height: 90rem;
  }
  .page-home .hero-image-wrapper .content-wrapper .heading {
    font-size: 5rem;
    line-height: 6rem;
    letter-spacing: 0;
  }
  .page-home .hero-image-wrapper .content-wrapper .logo-container {
    margin: 0;
  }
  .page-home .hero-image-wrapper .content-wrapper .logo-container.efe-emprende {
    max-width: 17rem;
  }
  .page-home .hero-image-wrapper .content-wrapper .logo-container.el-mundo {
    max-width: 15rem;
  }
  .page-home .hero-image-wrapper .content-wrapper .logo-container.el-mundo-financiero {
    max-width: 19.5rem;
  }
  .page-home .hero-image-wrapper .content-wrapper .logo-container.ei-estrategias {
    max-width: 16rem;
  }
  .page-home .info-cards {
    padding-top: 14.5rem;
    padding-bottom: 15rem;
  }
  .page-home .info-cards .section-heading {
    font-size: 3rem;
    line-height: 4.2rem;
    margin-bottom: 10rem;
  }
  .page-home .products {
    padding-top: 9.5rem;
    padding-bottom: 14.7rem;
  }
  .page-home .products .card-image {
    min-height: 42rem;
  }
  .page-home .video-banner {
    padding-top: 15rem;
    padding-bottom: 14.5rem;
  }
  .page-home .video-banner .section-heading {
    text-align: center;
    margin-bottom: 7.2rem;
  }
  .page-home .video-banner .card-video p {
    margin-top: 0rem;
  }
  .page-home .video-banner .card-video .text-content {
    padding-left: 10rem;
  }
  .page-home .why-us {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .page-home .why-us .section-heading {
    text-align: center;
    margin-bottom: 5rem;
  }
  .page-home .why-us .card-icon {
    padding: 3rem;
  }
  .page-home .why-us .btn {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 1280px) {
  .page-home .products .card-image {
    min-height: 60rem;
  }
  .page-home .why-us {
    padding-top: 13rem;
    padding-bottom: 12rem;
  }
  .page-home .why-us .section-heading {
    margin-bottom: 10rem;
  }
  .page-home .why-us .card-icon {
    padding: 4.5rem;
  }
  .page-home .why-us .btn {
    margin-top: 9rem;
  }
}
.page-about .hero-image {
  min-height: 75rem;
}
.page-about .hero-image .content-wrapper {
  color: #FFFFFF;
  max-width: 54.5rem;
}
.page-about .hero-image .content-wrapper .heading {
  font-size: 5rem;
  line-height: 6rem;
  letter-spacing: 0;
}
.page-about .hero-image .content-wrapper .text-content {
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0;
}
.page-about .about {
  padding-top: 5rem;
  background-color: #f8f8fa;
}
@media (min-width: 1024px) {
  .page-about .about {
    padding-top: 11rem;
  }
}
.page-about .about .sub-heading {
  text-align: center;
  font-size: 3rem;
  line-height: 6rem;
  letter-spacing: 0;
  font-weight: bold;
}
.page-about .about .text-content {
  text-align: center;
  max-width: 65rem;
  margin: 0 auto;
  margin-bottom: 5rem;
}
.page-about .about .text-content p {
  font-size: 1.8rem;
  line-height: 3rem;
  margin-bottom: 2rem;
}
.page-about .about .text-content p:last-of-type {
  margin-bottom: 2.5rem;
}
.page-about .about .text-content .legal-text {
  font-size: 1.2rem;
  font-weight: bold;
}
.page-about .about .bg-card {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 3rem 1rem;
}
.page-about .about .bg-card.h-limit {
  height: 24.5rem;
}
.page-about .about .bg-card .heading {
  font-size: 4rem;
  line-height: 4.6rem;
}
.page-about .about .bg-card p {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 3rem;
}
.page-about .about .card-circle {
  background-color: #FFFFFF;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 14rem;
  height: 11rem;
  margin: 2rem 0;
}
@media (min-width: 375px) {
  .page-about .about .card-circle {
    width: 15rem;
    height: 13rem;
  }
}
.page-about .about .download {
  background-color: #b70219;
  margin-top: 5.5rem;
  padding: 7.5rem 0;
}
.page-about .about .download p {
  font-size: 2rem;
  line-height: 3rem;
  color: #FFFFFF;
}
.page-about .about .brands {
  padding-top: 5rem;
  padding-bottom: 0;
}
@media (min-width: 1024px) {
  .page-about .about .brands {
    padding-top: 10rem;
  }
}
.page-about .about .brands .heading {
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 5.7rem;
}
.page-about .about .brands img {
  max-width: 100%;
}
.page-about .about .brands img.efe-emprende {
  width: 100%;
  width: 18.5rem;
}
.page-about .about .brands img.el-mundo {
  width: 100%;
  max-width: 15.7rem;
}
.page-about .about .brands img.el-mundo-financiero {
  width: 100%;
  max-width: 21rem;
}
.page-about .about .brands img.ei-estrategias-de-inversion¡ {
  width: 100%;
  max-width: 16rem;
}
.page-about .about .grid--values {
  grid-template-columns: repeat(2, 150px);
  grid-gap: 20px;
}
@media (min-width: 640px) {
  .page-about .about .grid--values {
    grid-template-columns: repeat(3, 150px);
  }
}
@media (min-width: 1280px) {
  .page-about .about .grid--values {
    grid-template-columns: repeat(6, 150px);
  }
}
.page-about .about .grid--values .card-circle label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
.page-about .about .grid--media {
  grid-template-columns: repeat(2, minmax(100px, 210px));
  grid-gap: 20px;
}
@media (min-width: 1024px) {
  .page-about .about .grid--media {
    grid-template-columns: repeat(4, 210px);
  }
}

.padding-bottom-banner {
  padding-bottom: 10rem;
}

@media screen and (min-width: 1024px) {
  .page-about .about {
    padding-top: 11rem;
  }
  .page-about .about .bg-card {
    padding: 5rem;
  }
  .page-about .about .text-content {
    margin-bottom: 15rem;
  }
}
.page-services .hero-image {
  min-height: 60rem;
}
.page-services .hero-image .content-wrapper {
  color: #FFFFFF;
  max-width: 54.5rem;
}
.page-services .hero-image .content-wrapper .heading {
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: 0;
}
.page-services .hero-image .content-wrapper .text-content {
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0;
}
.page-services .about {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f8f8fa;
}
@media (min-width: 1024px) {
  .page-services .about {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}
.page-services .about .text-content {
  text-align: center;
  max-width: 65rem;
  margin: 0 auto;
  margin-bottom: 5rem;
}
@media (min-width: 1024px) {
  .page-services .about .text-content {
    margin-bottom: 15rem;
  }
}
.page-services .about .text-content p {
  font-size: 1.8rem;
  line-height: 3rem;
}
.page-services .about .card-icon {
  background: transparent;
  padding: 2rem 1rem 2rem 5px;
}
.page-services .about .card-icon .icon {
  height: 75px;
  width: auto;
}
.page-services .about .card-icon .heading {
  font-size: 2.5rem;
  line-height: 3rem;
}
.page-services .about .card-icon .copy {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

@media (min-width: 640px) {
  .page-services .about .card-icon {
    background: #FFFFFF;
    padding: 4.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-services .hero-image {
    min-height: 75rem;
  }
  .page-services .hero-image .content-wrapper .heading {
    font-size: 5rem;
    line-height: 6rem;
  }
  .page-services .about {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .page-services .about .text-content {
    margin-bottom: 8rem;
  }
  .page-services .about .card-icon .heading {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}
@media (min-width: 1280px) {
  .page-services .about {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .page-services .about .text-content {
    margin-bottom: 15rem;
  }
  .page-services .about .card-icon .heading {
    font-size: 3rem;
    line-height: 3.7rem;
  }
}
.page-mortgage-income .hero-image {
  min-height: 67rem;
}
.page-mortgage-income .hero-image .logos {
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 15%;
  right: 15%;
}
@media (max-width: 1024px) {
  .page-mortgage-income .hero-image .logos {
    flex-direction: column;
    left: 10%;
    right: 10%;
  }
}
.page-mortgage-income .hero-image .logos .efe-emprende {
  width: 205px;
  margin: 0 !important;
  padding: 1rem;
}
.page-mortgage-income .hero-image .logos .el-mundo {
  width: 177px;
  margin: 0 !important;
  padding: 1rem;
}
.page-mortgage-income .hero-image .logos .el-mundo-financiero {
  width: 230px;
  margin: 0 !important;
  padding: 1rem;
}
.page-mortgage-income .hero-image .logos .ei-estrategias {
  width: 192px;
  margin: 0 !important;
  padding: 1rem;
}
.page-mortgage-income .hero-image .content-wrapper {
  color: #FFFFFF;
  max-width: 54.5rem;
}
.page-mortgage-income .hero-image .content-wrapper .heading {
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: 0;
}
.page-mortgage-income .hero-image .content-wrapper .text-content {
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0;
}
.page-mortgage-income .about {
  padding-top: 2rem;
  padding-bottom: 5.5rem;
}
.page-mortgage-income .about .hero-wrapper {
  width: 100%;
  height: 34rem;
}
.page-mortgage-income .about .hero-wrapper .hero-image {
  min-height: unset;
}
.page-mortgage-income .about .text-wrapper {
  margin-top: 4rem;
}
.page-mortgage-income .about .text-wrapper p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.page-mortgage-income .features .section-heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #000000;
}
.page-mortgage-income .features .secondary-copy {
  margin-top: 6rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.page-mortgage-income .download {
  background-color: #b70219;
  margin-top: 5.5rem;
  padding: 7.5rem 0;
}
.page-mortgage-income .download p {
  font-size: 2rem;
  line-height: 3rem;
  color: #FFFFFF;
}
.page-mortgage-income .news {
  padding-top: 5rem;
  padding-bottom: 0;
}
.page-mortgage-income .news .section-heading {
  margin-bottom: 3rem;
}
.page-mortgage-income .contact {
  background-color: #000000;
  padding-top: 14.5rem;
  padding-bottom: 15rem;
}
.page-mortgage-income .contact .section-heading {
  color: #FFFFFF;
  margin-bottom: 5rem;
}
.page-mortgage-income.page-product .presence-media {
  padding-bottom: 7rem;
}
.page-mortgage-income.page-product .presence-media .heading {
  margin-bottom: 3rem;
}

@media screen and (min-width: 1024px) {
  .page-mortgage-income .hero-image {
    min-height: 75rem;
  }
  .page-mortgage-income .hero-image .content-wrapper .heading {
    font-size: 5rem;
    line-height: 6rem;
  }
  .page-mortgage-income .about {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .page-mortgage-income .about .hero-wrapper {
    width: 56.6rem;
    height: 56.6rem;
  }
  .page-mortgage-income .about .text-wrapper {
    max-width: 56.6rem;
    margin-top: 0rem;
  }
  .page-mortgage-income .about .text-wrapper p {
    padding-left: 6rem;
  }
  .page-mortgage-income .features .section-heading {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  .page-mortgage-income .features .secondary-copy {
    margin-top: 6rem;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .page-mortgage-income .news {
    padding-top: 15rem;
    padding-bottom: 0;
  }
  .page-mortgage-income .news .section-heading {
    margin-bottom: 8rem;
  }
  .page-mortgage-income .download {
    margin-top: 15rem;
  }
  .page-mortgage-income .brands {
    padding-bottom: 10rem;
  }
  .page-mortgage-income .brands.how-to-invest {
    padding-bottom: 1rem;
  }
  .page-mortgage-income.page-product .presence-media {
    padding-bottom: 15rem;
  }
  .page-mortgage-income.page-product .presence-media .heading {
    padding-top: 14rem;
  }
}
@media screen and (min-width: 1366px) {
  .page-mortgage-income .about .text-wrapper p {
    padding-left: 0;
  }
}
.page-legal-notice {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}
.page-legal-notice .heading-3 {
  font-size: 3rem;
  line-height: 4.2rem;
  margin-bottom: 4rem;
}
.page-legal-notice .parragraph-heading,
.page-legal-notice p {
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.page-legal-notice p {
  font-family: "Nunito Sans", sans-serif;
}

@media screen and (min-width: 1024px) {
  .page-legal-notice {
    padding-top: 14.5rem;
    padding-bottom: 15rem;
  }
  .page-legal-notice .heading-3 {
    font-size: 4rem;
  }
}
.page-faqs .parragraph-heading {
  font-size: 1.8rem;
  line-height: 3rem;
}
.page-faqs ul {
  list-style: disc;
  padding-left: 3rem;
}
.page-faqs ul li {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-family: "Nunito Sans", sans-serif;
}
.page-faqs .collapsible-wrapper .collapsible span {
  font-size: 1.8rem;
  line-height: 3rem;
  text-align: left;
}
.page-faqs .collapsible-wrapper .collapsible span.icon {
  font-size: 2.5rem;
}
.page-faqs .collapsible-wrapper .content p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.page-contact .hero-image-wrapper {
  padding: 100px 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60rem;
}
@media (max-width: 599px) {
  .page-contact .hero-image-wrapper {
    padding-bottom: 0;
  }
}
.page-contact .hero-image-wrapper .content-wrapper {
  color: #FFFFFF;
  max-width: 54.5rem;
}
.page-contact .hero-image-wrapper .content-wrapper .heading {
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: 0;
  color: white;
}
@media (min-width: 1024px) {
  .page-contact .hero-image-wrapper .content-wrapper .heading {
    font-size: 5rem;
  }
}
.page-contact .main-banner .icon-wrapper {
  min-width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-contact .main-banner a,
.page-contact .main-banner p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #000000 !important;
}
@media (min-width: 768px) {
  .page-contact .main-banner .contact-info {
    height: 52px;
    display: flex;
    align-items: center;
  }
}
.page-contact .form-wrapper {
  padding: 5rem 0 10rem;
  background: linear-gradient(180deg, white 0%, #eaeaea 35%);
}
.page-contact .form-wrapper .section-heading {
  font-size: 1.8rem;
  line-height: 3rem;
  margin-bottom: 5rem;
}
.page-contact .form-wrapper .tw-container {
  max-width: 960px !important;
}
.page-contact .form-wrapper .v-input {
  font-family: "Nunito Sans", sans-serif;
}
.page-contact .form-wrapper textarea {
  margin-top: 20px !important;
}
.page-contact iframe {
  width: 100%;
  height: 58rem;
}

@media (min-width: 1024px) {
  .page-contact .form-wrapper {
    padding: 0 0 10rem;
  }
}
.wizard-page {
  min-height: 100vh;
  background-color: #f8f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news {
  padding-top: 160px;
}
@media (max-width: 768px) {
  .news {
    padding-top: 80px;
  }
}
.news .news-container {
  max-width: 600px !important;
  padding: 1rem;
}
@media (min-width: 1024px) {
  .news .news-container {
    max-width: 936px !important;
  }
}
.news--title {
  font-family: "Nunito Sans", sans-serif;
  font-size: 4rem !important;
  font-weight: bold;
}
.news--subtitle {
  font-size: 1.8rem !important;
  font-family: "Nunito Sans", sans-serif;
}
.news .margin-titles {
  margin-left: 40px;
}
@media (max-width: 640px) {
  .news .margin-titles {
    margin-left: 0;
  }
}
.news .news-card {
  max-width: 360px;
  width: 100%;
  height: 440px;
  margin: 20px 40px;
}
@media (max-width: 1023px) {
  .news .news-card {
    height: auto;
    margin: 30px 40px;
  }
  .news .news-card:last-child {
    padding-bottom: 40px;
  }
}
@media (max-width: 640px) {
  .news .news-card {
    margin: 30px 0;
  }
}
.news .news-card .image .news-image {
  width: 100%;
  height: 189px;
  object-fit: cover;
}
.news .news-card .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.news .news-card .info .title, .news .news-card .info .short-description, .news .news-card .info .news-link {
  font-family: "Nunito Sans", sans-serif;
}
.news .news-card .info a {
  margin-top: 30px;
}
.news .news-card .info .title {
  font-size: 20px;
  font-weight: bold;
}
.news .news-card .info .short-description {
  margin-top: 14px;
  font-size: 16px;
}
.news .news-card .info .news-link {
  display: inline-block;
  margin-top: 20px;
  position: relative;
  color: black;
}
.news .news-card .info .news-link:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: -5px;
  left: 0;
  background-color: black;
  height: 2px;
}
.news .news-card .info .news-link:hover {
  color: #b70219;
}
.news .news-card .info .news-link:hover:after {
  background-color: #b70219;
}
.news .news-card a {
  color: black;
}
.news .news-card.hovered .title, .news .news-card.hovered .news-link {
  color: #b70219;
}
.news .news-card.hovered .news-link:after {
  background-color: #b70219;
}
.news .news-list {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .news .news-list {
    margin-top: 70px;
  }
}
.news .title-mobile-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
@media (min-width: 769px) {
  .news .title-mobile-container {
    display: none;
  }
}
.news .title-mobile-container h2 {
  font-size: 35px !important;
}
.news .title-bg-image {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  max-height: 500px;
  z-index: 0;
}
.news .title-bg-image:before {
  content: "";
  display: block;
  padding-top: 62.5%;
}
.news .title-bg-image .opacity-black {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.news .title-bg-image .main_image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.news .title-bg-image .title-container {
  z-index: 20;
  color: white;
  width: 70%;
  padding: 35px;
}
@media (max-width: 768px) {
  .news .title-bg-image .title-container {
    display: none;
  }
}
.news .title-bg-image .title-container .title {
  width: 80%;
  margin-bottom: 25px;
}
.news .content .date-container {
  margin-top: 24px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .news .content .date-container {
    flex-direction: column-reverse;
  }
}
.news .content .date-container .at-share-btn-elements {
  display: flex;
  justify-content: center;
}
.news .content .date-container .date {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .news .content .date-container .date {
    margin-top: 50px;
  }
}
.news .content .rich-text {
  font-family: "Nunito Sans", sans-serif !important;
  padding-bottom: 50px;
  border-bottom: 1px solid #979797;
}
.news .content .rich-text p {
  font-size: 16px;
  margin-bottom: 1rem;
}
.news .content .rich-text ul, .news .content .rich-text ol {
  padding-left: 1.7rem;
  margin-top: 2rem;
}
.news .content .rich-text ul li, .news .content .rich-text ol li {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 16px;
  margin-bottom: 1rem;
}
.news .content .rich-text h2 {
  font-size: 22px;
  margin-bottom: 2rem;
  font-weight: 900;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .news .content .rich-text h2 {
    margin-top: 18px;
  }
}
.news .related {
  margin-top: 89px;
}
.news .related-container {
  max-width: 600px !important;
  padding: 0 1rem;
}
@media (min-width: 1024px) {
  .news .related-container {
    max-width: 1100px !important;
  }
}
.news .related h1 {
  font-size: 30px !important;
  font-weight: 600;
}
.news .related-news:focus {
  outline: none;
}
.news .related-news .flickity-viewport {
  margin: 0 80px;
}
.news.news-index {
  padding-bottom: 50px;
}
@media (min-width: 1024px) {
  .news.news-index {
    padding-bottom: 80px;
  }
}
.news.news-index .news-card {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .news.news-index .news-card {
    max-width: none;
    width: 50%;
    margin: 40px 0;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1024px) {
  .news.news-index .news-card {
    width: calc(100% / 3);
  }
}
.news.news-show .title-mobile-container h1 {
  font-size: 35px;
}
@media (min-width: 1024px) {
  .news.news-show .title-mobile-container h1 {
    font-size: 40px;
  }
}
.news.news-show .related-news .news-card .short-description {
  height: 88px;
}
@media (min-width: 641px) {
  .news nav[role=navigation] {
    padding: 0 40px;
  }
}

.bg-grey {
  background-color: #f9f9f9;
}

.legal-component {
  padding: 10rem 0 4rem;
}
@media (min-width: 1024px) {
  .legal-component {
    padding: 10rem 0;
  }
}
.legal-component .select {
  border-radius: 6px;
  height: 41px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
  line-height: 39px;
}
@media (max-width: 1023px) {
  .legal-component .select {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .legal-component .select .material-icons {
    transition: transform 0.2s ease-in-out;
  }
  .legal-component .select .material-icons.open {
    transform: rotate(180deg);
  }
}
.legal-component .select .options {
  top: 100%;
  width: 100%;
  box-shadow: inherit;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.legal-component .select .options .option {
  font-family: "Nunito Sans", sans-serif;
  background: white;
}
.legal-component .select .options .option.selected {
  background: #b70219;
  color: white;
}
.legal-component .select .options .option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.legal-component .select span {
  font-family: "Nunito Sans", sans-serif;
}
.legal-component h3 {
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 4rem;
}
@media (min-width: 1024px) {
  .legal-component h3 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 4rem;
  }
}
.legal-component h4 {
  margin-top: 3rem;
}
.legal-component a {
  font-weight: 500;
  text-decoration: underline;
  color: #b70219;
  font-family: "Nunito Sans", sans-serif;
}
.legal-component p {
  margin-top: 1.5rem;
  font-family: "Nunito Sans", sans-serif;
}
.legal-component .menu-container {
  min-width: 210px;
  background-color: white;
  top: 75px;
}
.legal-component .fixed-menu {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
  cursor: pointer;
}
.legal-component .fixed-menu li {
  width: 210px;
  flex-grow: 1;
  font-weight: normal;
  font-size: 14px;
  padding: 10px 20px;
}
.legal-component .fixed-menu li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.legal-component .fixed-menu li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.legal-component .fixed-menu li.active {
  color: white;
  background: #b70219;
  font-weight: 500;
}
.legal-component table, .legal-component th, .legal-component td {
  border: 1px solid black;
  font-family: "Nunito Sans", sans-serif;
}
.legal-component table {
  margin: 2rem 0;
  font-size: 1.6rem;
  border-collapse: collapse;
}
.legal-component table th {
  text-align: start;
}
.legal-component table th, .legal-component table td {
  padding: 1rem;
}
@media (max-width: 992px) {
  .legal-component table th, .legal-component table td {
    padding: 0.5rem;
  }
}