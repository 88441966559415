html {
  font-size: 62.5%!important; // 1rem === 10px
}

.base{
    background-color: $color-white;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input,
span,
a {
  font-family: $font-nimbus;
  font-size: 1.6rem;
  font-weight: $font-regular;
}
p{
  line-height: 3rem;
}
button,
a {
  line-height: 1.15;
}

a {
  text-decoration: none!important;
}

[v-cloak] {
  display: none;
}

.thank-you-container {
  height: 60vh;
}