.section-heading {
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: $color-black;
  margin-bottom: 7.2rem;
}

@media screen and (min-width: 1024px) {
  .section-heading {
    font-size: 3rem;
    line-height: 5.8rem;
  }
}

.tw-container {
  max-width: 100% !important;
  padding: 0 16px !important;

  @media (min-width: 600px) {
    width: 90%;
    max-width: 1280px !important;
  }
}
