.card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem 2.5rem;
  color: $color-white;

  .heading {
    font-size: 4rem;
    line-height: 4.6rem;
  }

  .copy {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-top: 2.4rem;
  }

  .btn {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .card-image {
    padding: 6.5rem 4.6rem;
  }
}
