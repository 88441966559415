.steps {
  .wrapper {
    background: $color-grey-5;
  }

  .custom-container {
    max-width: 600px;
    margin: auto;

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 20rem;
      flex-direction: column;

      label {
        font-size: 2.5rem;
        line-height: 4.6rem;
      }

      img, label {
        margin-bottom: 2.5rem;
      }

      p {
        font-family: $font-nunito-sans;
        line-height: 2.8rem;
      }

      + .item {
        margin-top: 5rem;
      }
    }
  }

  .right {
    display: none;
  }
}

@media (min-width: 1024px) {
  .steps {
    .wrapper {
      background: linear-gradient(90deg, $color-grey-5 50%, #fff 50%);
    }

    .custom-container {
      max-width: 1200px;

      .item {
        flex-direction: row;
        align-items: center;
        padding: 5rem;

        p {
          padding-left: 5rem;
        }

        label {
          margin-left: 4rem;
        }

        img, label {
          margin-bottom: 0;
        }
      }
    }

    .right {
      display: block;

      .item {
        p {
          font-family: $font-nunito-sans;
          line-height: 2.8rem;
        }
      }
    }
  }
}
