
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    z-index: 1500;

    @media (min-width: 993px) {
      height: 200px;
    }
    @media (min-width: 1200px) {
      height: 160px;
    }
    @media (max-width:992px) {
        padding: 15px 30px;
        height: unset;
    }
    @media (max-width:767px) {
        flex-direction: column;
    }
    &__container{
        display: flex;
        flex-direction: column;
        padding: 15px 15px;

        .cookie-consent__message {
            font-family: $font-nunito-sans;
            font-size: 22px;
            font-weight: 900;
            @media (max-width:992px) {
                font-size: 18px;
            }

            &-subtitle{
                font-family: $font-nunito-sans;
                font-size: 18px;
                font-weight: normal;
                @media (max-width:992px) {
                    font-size: 14px;
                }
                a {
                    color: white;
                    text-decoration: none;
                    font-family: $font-nunito-sans !important;
                    font-size: 18px !important;
                    @media (max-width:992px) {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    &__buttons {
        display: flex;
        flex-direction: row-reverse;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        button, a {
            margin: 10px 5px;
            @media (min-width: 1200px) {
                margin: 10px 10px;
            }
        }
        .btn-outlined {
            &:hover {
                background: darken($color-white, 15%);
            }
        }
    }
}


