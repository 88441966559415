.form-group {
  // margin: 0rem 0;
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    font-weight: 600;
    font-family: $font-nunito-sans;
    margin-bottom: 5px;
  }
  .v-input__slot {
    border-radius: 10px !important;
    height: 50px;
    min-height: unset !important;
    margin-bottom: 0.2px!important;
    // border: solid 0.5px #c0bfd1!important;
    .v-text-field__details{
      margin-bottom: 0.2px!important;
    }
    input {
      font-family: $font-nunito-sans;
    }
  }
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background: white;
  }
  .red {
    color: #b70219 !important;
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}
.v-messages__message{
  color: red;
}


.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid black!important;
}
